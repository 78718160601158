const REGLEMENT_URL = `/reglement`;
const PASSIF_REGLEMENT_URL = `/passif-reglement`;

import _reduce from "lodash-es/reduce";

export default {
  state: {
    reglements: [],
    default_pagination: {
      current: 1,
      total: null,
      pageSize: 10,
    },
    pagination: {
      current: 1,
      total: null,
      pageSize: 10,
    },
  },
  getters: {
    getUserListPaiementSoldSomme(state) {
      return _reduce(
        state.reglements,
        function(sum, reglement) {
          return sum + reglement.montant;
        },
        0
      );
    },
    getUserReglementPagination(state) {
      return {
        per_page: state.pagination.pageSize,
        page: state.pagination.current,
      };
    },
  },
  mutations: {
    setUserReglements(state, data) {
      state.reglements = data;
    },
    setUserReglementPagination(state, data) {
      state.pagination = data;
    },
    setUserReglementDefaultPagination(state) {
      state.pagination = state.default_pagination;
    },
    setUserReglementPaginationFromServer(state, data) {
      state.pagination = {
        pageSize: Number(data.per_page),
        current: Number(data.current_page),
        total: Number(data.total),
      };
    },
  },
  actions: {
    async storePassifReglement({ getters }, payload) {
      try {
        return await getters.authenticatedAxios.post(
          `${PASSIF_REGLEMENT_URL}`,
          payload
        );
      } catch (e) {
        return e;
      }
    },
    async updateUserReglement({ getters }, reglement) {
      try {
        return await getters.authenticatedAxios.put(
          `${REGLEMENT_URL}/${reglement.id}`,
          reglement
        );
      } catch (e) {
        throw e;
      }
    },
    async destroyUserReglement({ getters }, id) {
      try {
        return await getters.authenticatedAxios.delete(
          `${REGLEMENT_URL}/${id}`
        );
      } catch (e) {
        throw e;
      }
    },
    async storeUserReglement({ getters }, reglement) {
      try {
        return await getters.authenticatedAxios.post(REGLEMENT_URL, reglement);
      } catch (e) {
        throw e;
      }
    },
    async fetchUserReglements({ commit, getters, dispatch }, data) {
      try {
        let payload = {
          ...data,
          ...getters.getUserReglementPagination
        };
        return await dispatch(
          "fetchUserReglementsWithoutSettingState",
          payload
        ).then((response) =>{
          commit("setUserReglements", response.data.data);
          commit("setUserReglementPaginationFromServer", response.data.meta);
        }
        );
      } catch (e) {
        return e;
      }
    },
    async fetchUserReglementsWithoutSettingState({ getters }, payload) {
      try {
        return await getters.authenticatedAxios.get(`${REGLEMENT_URL}`, {
          params: {
            ...payload,
          },
        });
      } catch (e) {
        return e;
      }
    },
  },
};
