const ETAT_ACTIVITE_URL = `/admin/user/etat-activite`;
const DELETE_ETAT_ACTIVITE_FILE_URL = `/etat-activite/upload-documents`;

export default {
  state: {
    adminEtatActivites: [],
  },
  getters: {},
  mutations: {
    setAdminUserEtatActivites(state, data) {
      state.adminEtatActivites = data;
    },
  },
  actions: {
    async storeAdminUserEtatActvite({ getters }, etatActivite) {
      try {
        return await getters.authenticatedAxios.post(
          ETAT_ACTIVITE_URL,
          etatActivite
        );
      } catch (e) {
        throw e;
      }
    },
    async updateAdminUserEtatActvite({ getters }, etatActivite) {
      try {
        return await getters.authenticatedAxios.put(
          `${ETAT_ACTIVITE_URL}/${etatActivite.id}`,
          etatActivite
        );
      } catch (e) {
        throw e;
      }
    },
    async destroyAdminUserEtatActviteFile({ getters }, etatActivite) {
      try {
        return await getters.authenticatedAxios.delete(
          `${DELETE_ETAT_ACTIVITE_FILE_URL}/${etatActivite}`
        );
      } catch (e) {
        throw e;
      }
    },
    async destroyAdminUserEtatActvite({ getters }, etatActivite) {
      try {
        return await getters.authenticatedAxios.delete(
          `${ETAT_ACTIVITE_URL}/${etatActivite}`
        );
      } catch (e) {
        throw e;
      }
    },
    async fetchAdminUserEtatActivites({ dispatch, commit }, userId) {
      try {
        return await dispatch(
          "fetchAdminUserEtatActivitesWithoutSettingState",
          userId
        ).then((response) =>
          commit("setAdminUserEtatActivites", response.data.data)
        );
      } catch (e) {
        return e;
      }
    },
    async fetchAdminUserEtatActivitesWithoutSettingState({ getters }, userId) {
      try {
        return await getters.authenticatedAxios.get(
          `${ETAT_ACTIVITE_URL}/${userId}`
        );
      } catch (e) {
        return e;
      }
    },
  },
};
