const URL = `/admin/log`;

export default {
  state: {
    logs: [],
    default_pagination: {
      current: 1,
      total: 0,
      pageSize: 10,
    },
    logPagination: {
      current: 1,
      total: 0,
      pageSize: 10,
    },
  },
  getters: {
    getLogs(state) {
      return state.logs;
    },
    getLogPagination(state) {
      return {
        per_page: state.logPagination.pageSize,
        page: state.logPagination.current,
      };
    },
  },
  mutations: {
    setLogs(state, data) {
      state.logs = data;
    },
    setLogPagination(state, data) {
      state.logPagination = data;
    },
    setLogDefaultPagination(state) {
      state.logPagination = state.default_pagination;
    },
    setLogPaginationFromServer(state, data) {
      state.logPagination = {
        pageSize: Number(data.per_page),
        current: Number(data.current_page),
        total: Number(data.total),
      };
    },
  },
  actions: {
    async fetchLog({ getters, commit }, payload) {
      try {
        let para = {
          ...payload,
          ...getters.getLogPagination,
        };
        return await getters.authenticatedAxios
          .get(`${URL}`, {
            params: { ...para },
          })
          .then((response) => {
            commit("setLogs", response.data.data);
            commit("setLogPaginationFromServer", response.data.meta);
          });
      } catch (e) {
        throw e;
      }
    },
  },
};
