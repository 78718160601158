const ATTESTATIONS_URL = `/admin/attestation`;
const DEMANDE_ATTESTATIONS_URL = `/admin/attestation/new-demande-count`;
const ATTESTATIONS_STATE_CHANGE_URL = `/admin/attestation`;

export default {
  state: {
    attestations: [],
    demandesAttestationsCount: null,
    default_pagination: {
      current: 1,
      total: null,
      pageSize: 10,
    },
    pagination: {
      current: 1,
      total: null,
      pageSize: 10,
    },
  },
  getters: {
    getAttestations(state) {
      return state.attestations;
    },
    getDemandesAttestationsCount(state) {
      return state.demandesAttestationsCount;
    },

    getAttestationPagination(state) {
      return {
        per_page: state.pagination.pageSize,
        page: state.pagination.current,
      };
    },
  },
  mutations: {
    setAttestations(state, data) {
      state.attestations = data;
    },
    setDemandeAttestationCount(state, data) {
      state.demandesAttestationsCount = data.count;
    },
    setNewDemandesAttestationsCount(state, data) {
      state.demandesAttestationsCount = data;
    },
    setAttestationPagination(state, data) {
      state.pagination = data;
    },
    setAttestationDefaultPagination(state) {
      state.pagination = state.default_pagination;
    },
    setAttestationPaginationFromServer(state, data) {
      state.pagination = {
        pageSize: Number(data.per_page),
        current: Number(data.current_page),
        total: Number(data.total),
      };
    },
  },
  actions: {
    async fetchAttestations({ getters, commit }, payload) {
      try {
        let param = {
          ...payload,
          ...getters.getAttestationPagination,
        };
        return await getters.authenticatedAxios
          .get(ATTESTATIONS_URL, {
            params: {
              ...param,
            },
          })
          .then((response) => commit("setAttestations", response.data.data));
      } catch (e) {
        return e;
      }
    },
    async storeAttestations({ getters }, attestation) {
      try {
        return await getters.authenticatedAxios.post(
          ATTESTATIONS_URL,
          attestation
        );
      } catch (e) {
        throw e;
      }
    },
    async updateAttestation({ getters }, attestation) {
      try {
        return await getters.authenticatedAxios.put(
          `${ATTESTATIONS_URL}/${attestation.id}`,
          attestation
        );
      } catch (e) {
        throw e;
      }},
    async fetchNewDemandeAttestationCount({ getters, commit }) {
      try {
        return await getters.authenticatedAxios
          .get(DEMANDE_ATTESTATIONS_URL)
          .then((response) =>
            commit("setDemandeAttestationCount", response.data)
          );
      } catch (e) {
        return e;
      }
    },
    async destroyAttestation({ getters }, id) {
      try {
        return await getters.authenticatedAxios.delete(
          `${ATTESTATIONS_URL}/${id}`
        );
      } catch (e) {
        throw e;
      }
    },
    async changeAttestationState({ getters }, payload) {
      try {
        return await getters.authenticatedAxios.put(
          `${ATTESTATIONS_STATE_CHANGE_URL}/${payload.id}`,
          {
           ...payload
          }
        );
      } catch (e) {
        throw e;
      }
    },
  },
};
