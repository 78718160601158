<template>
    <a-config-provider :locale="fr_FR">
        <router-view></router-view>
    </a-config-provider>
</template>
<script>
    import fr_FR from 'ant-design-vue/lib/locale-provider/fr_FR';
    export default {
        name: 'app',
        data() {
            return {
                fr_FR,
            }
        }
    }
</script>
<style>
    .components-layout-demo-custom-trigger .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color .3s;
        height: inherit;
    }

    .components-layout-demo-custom-trigger {
        height: inherit;
    }

    .components-layout-demo-custom-trigger .trigger:hover {
        color: #1890ff;
    }

    .components-layout-demo-custom-trigger .logo {
        height: 36px;
        /*background: rgba(255, 255, 255, .2);*/
        margin: 16px 16px 23px;
        text-align: center;
        color: white;
        font-size: large;
    }
     .ant-table{
      overflow-x: auto;
    }
    .ant-input[disabled]{
        color : black  !important;
    }
</style>
