const ACCUEIL_URL = `/admin/accueil`
const INDEX_PAGE = `/index_page`
export default {
    state: {accueil: {},index_page : { events : [], membres : [], documents : []}},
    mutations: {
        setAccueil(state, data) {
            state.accueil = data;
        },
        setIndexPage(state, data) {
            state.index_page           = data;
            state.index_page.events    = data.events.data;
            state.index_page.membres   = data.membres.data;
            state.index_page.documents = data.documents.data;
        },
    },
    actions: {
        async fetchAccueil({getters, commit}) {
            try {
                return await getters.authenticatedAxios.get(INDEX_PAGE)
                    .then((response) => commit('setAccueil', response.data));

            } catch (e) {
                return e;
            }
        },
        async fetchIndexPage({getters, commit}) {
            try {
                return await getters.authenticatedAxios.get(INDEX_PAGE)
                    .then((response) => commit('setIndexPage', response.data));

            } catch (e) {
                return e;
            }
        },
        async updateAccueil({getters}, accueil) {
            try {
                return await getters.authenticatedAxios.put(
                    `${ACCUEIL_URL}/${accueil.id}`,
                    accueil
                );
            } catch (e) {
                throw e;
            }
        },
 

    }

};
