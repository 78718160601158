const REGLEMENT_URL = `/reglement`;
const PASSIF_REGLEMENT_URL = `/passif-reglement`;
const REGLEMENT_USER_URL = `/reglement`;
import moment from "moment";
// import get from "lodash-es/get";
import reduce from "lodash-es/reduce";

export default {
  state: {
    reglements: [],
    selected_reglement: null,
    default_pagination: {
      current: 1,
      total: null,
      pageSize: 10,
    },
    pagination: {
      current: 1,
      total: null,
      pageSize: 10,
    },
  },
  getters: {
    getListPaiementSoldSomme(state) {
      return reduce(
        state.reglements,
        function(sum, reglement) {
          return sum + reglement.montant;
        },
        0
      );
    },
    getReglementPagination(state) {
      return {
        per_page: state.pagination.pageSize,
        page: state.pagination.current,
      };
    },
  },
  mutations: {
    setAdminReglements(state, data) {
      state.reglements = data;
    },
    setAdminSelectedReglement(state, data) {
      state.selected_reglement = data;
    },
    clearSelectedCotisation(state) {
      state.selected_reglement = null;
    },
    adminFilteredAdherentListOfReglement(state, value) {
      let isValueIn = function(needle, haystack) {
        return haystack
          ? haystack
              .toString()
              .toLowerCase()
              .includes(needle.toLowerCase())
          : false;
      };
      state.reglements = state.reglements.filter((reglement) => {
        return (
          isValueIn(value, reglement.adherant.nom) ||
          isValueIn(value, reglement.adherant.prenom) ||
          isValueIn(value, reglement.adherant.matricule) ||
          isValueIn(value, reglement.exercice.annee) ||
          isValueIn(value, reglement.mode.name) ||
          isValueIn(value, reglement.code) ||
          isValueIn(value, reglement.montant) ||
          isValueIn(value, reglement.refChkVir) ||
          isValueIn(value, moment(reglement.dtEncaiss).format("DD/MM/YYYY")) ||
          isValueIn(value, moment(reglement.dtRefChkVir).format("DD/MM/YYYY"))
        );
      });
    },

    setReglementPagination(state, data) {
      state.pagination = data;
    },
    setReglementDefaultPagination(state) {
      state.pagination = state.default_pagination;
    },
    setReglementPaginationFromServer(state, data) {
      state.pagination = {
        pageSize: Number(data.per_page),
        current: Number(data.current_page),
        total: Number(data.total),
      };
    },
  },
  actions: {
    async storeAdminPassifReglement({ getters }, payload) {
      try {
        return await getters.authenticatedAxios.post(
          `${PASSIF_REGLEMENT_URL}`,
          payload
        );
      } catch (e) {
        return e;
      }
    },
    async storeReglement({ getters }, reglement) {
      try {
        return await getters.authenticatedAxios.post(REGLEMENT_URL, reglement);
      } catch (e) {
        throw e;
      }
    },
    async fetchAdminReglements({ dispatch, commit, getters }, data) {
      try {
        let payload = {
          ...data,
          ...getters.getReglementPagination,
        };
        return await dispatch(
          "fetchAdminReglementsWithoutSettingState",
          payload
        ).then((response) => {
          commit("setAdminReglements", response.data.data);
          commit("setReglementPaginationFromServer", response.data.meta);
        });
      } catch (e) {
        throw e;
      }
    },
    async fetchAdminReglementById({ getters, commit }, id) {
      try {
        return await getters.authenticatedAxios
          .get(`${REGLEMENT_URL}/${id}`)
          .then((response) =>
            commit("setAdminSelectedReglement", response.data)
          );
      } catch (e) {
        return e;
      }
    },
    async adminFilteredAdherentListOfReglement({ dispatch, commit }, value) {
      try {
        return await dispatch("fetchAdminReglements").then(() =>
          commit("adminFilteredAdherentListOfReglement", value)
        );
      } catch (e) {
        return e;
      }
    },
    async updateAdminReglement({ getters }, reglement) {
      try {
        return await getters.authenticatedAxios.put(
          `${REGLEMENT_URL}/${reglement.id}`,
          reglement
        );
      } catch (e) {
        throw e;
      }
    },
    async destroyAdminReglement({ getters }, id) {
      try {
        return await getters.authenticatedAxios.delete(
          `${REGLEMENT_URL}/${id}`
        );
      } catch (e) {
        throw e;
      }
    },
    async fetchAdminReglementsWithoutSettingState({ getters }, payload) {
      try {
        return await getters.authenticatedAxios.get(
          `${REGLEMENT_USER_URL}`,
          {
            params: {
              ...payload,
            },
          }
        );
      } catch (e) {
        throw e;
      }
    },
  },
};
