const URL = `/specialite`;
export default {
  state: {
    specialites: [],
  },
  getters: {
    getSpecialites(state) {
      return state.specialites;
    },
  },
  mutations: {
    setSpecialites(state, data) {
      state.specialites = data;
    },
  },
  actions: {
    async fetchSpecialites({ dispatch, commit }) {
      try {
        return dispatch(
          "fetchSpecialitesWithoutSettingState",
          URL
        ).then((response) => commit("setSpecialites", response.data.data));
      } catch (e) {
        throw e;
      }
    },
    async fetchSpecialitesWithoutSettingState({ getters }, url) {
      try {
        return await getters.authenticatedAxios.get(url);
      } catch (e) {
        throw e;
      }
    },
  },
};
