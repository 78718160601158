const TYPE_VISITES_URL = `/type-visites`;
export default {
    state: {
        type_visites: [],
    },
    getters: {
        getTypeVisites(state) {
            return state.type_visites;
        },
    },
    mutations: {
        setTypeVsites(state, data) {
            state.type_visites = data;
        },
    },
    actions: {
        async fetchTypeVisites({getters, commit}, payload) {
            try {
                return await getters.authenticatedAxios.get(TYPE_VISITES_URL, {
                    params: {
                        ...payload,
                    },
                })
                    .then((response) => commit("setTypeVsites", response.data.data));
            } catch (e) {
                return e;
            }
        },
        async updateTypeVisites({getters}, typeVisite) {
            try {
                return await getters.authenticatedAxios.put(
                    `${TYPE_VISITES_URL}/${typeVisite.id}`,
                    typeVisite
                );
            } catch (e) {
                throw e;
            }
        },

    },
};
