const ORGANISME_URL = `/organisme`;
export default {
  state: {
    organismes: [],
    selected_organisme: null,
  },
  getters: {},
  mutations: {
    setOrganismes(state, data) {
      state.organismes = data;
    },
    setSelectedOrganisme(state, data) {
      state.selected_organisme = data;
    },
    clearSelectedOrganisme(state) {
      state.selected_organisme = null;
    },
  },
  actions: {
    async storeOrganisme({ getters, dispatch }, organisme) {
      try {
        return await getters.authenticatedAxios
            .post(ORGANISME_URL, organisme);
      } catch (e) {
        throw e;
      }
    },
    async fetchOrganismes({ dispatch, commit }, payload) {
      try {
        return await dispatch(
          "fetchOrganismesWithoutSettingState",
          payload
        ).then((response) => commit("setOrganismes", response.data.data));
      } catch (e) {
        throw e;
      }
    },
    async fetchOrganismesWithoutSettingState({ getters }, payload) {
      try {
        return await getters.authenticatedAxios.get(ORGANISME_URL, {
          params: {
            ...payload,
          },
        });
      } catch (e) {
        throw e;
      }
    },
    async updateOrganisme({ getters, dispatch }, organisme) {
      try {
        return await getters.authenticatedAxios
            .put(`${ORGANISME_URL}/${organisme.id}`, organisme);
      } catch (e) {
        throw e;
      }
    },
    async destroyOrganisme({ getters, dispatch }, organisme) {
      try {
        return await getters.authenticatedAxios
            .delete(`${ORGANISME_URL}/${organisme.id}`);
      } catch (e) {
        throw e;
      }
    },
  },
};
