const CONTACTS_URL = `/admin/contact`
const MARK_CONTACT_AS_READ_URL = `/admin/contact/mark-as-read`

export default {
    state: {
        contacts: [],
    },
    mutations: {
        setContacts(state, data) {
            state.contacts = data;
        }
    },
    actions: {
        async fetchContacts({getters, commit}) {
            try {
                return await getters.authenticatedAxios.get(CONTACTS_URL)
                    .then(response => commit("setContacts", response.data.data));
            } catch (e) {
                return e;
            }
        },
        async markContactAsRead({getters} ,contactId) {
            // console.warn('Contact ', contactId);
            try {
                return await getters.authenticatedAxios.put(`${MARK_CONTACT_AS_READ_URL}/${contactId}`);
            } catch (e) {
                return e;
            }
        }
    },
};
