import get from "lodash-es/get";

const MESSAGE_COMMUNICATION_URL = `/admin/message-communication`;
const GET_MESSAGE_COMMUNICATION_URL = `/message-communication`;

export default {
  state: {
    messages_communications: {},
  },
  getters: {
    getDemandeInscriptinSuccessMessage(state){
        return get(state, 'messages_communications.demande_inscription')
    },
    getDemandeOuvertureSuccessMessage(state){
      return get(state, 'messages_communications.demande_ouverture')
    },
    getDemandeTransfertSuccessMessage(state){
      return get(state, 'messages_communications.demande_transfert')
    },
    getReglementInterieur(state){
      return get(state, 'messages_communications.reglement_interieur')
    }
  },
  mutations: {
    setMessagesCommunications(state, data) {
      state.messages_communications = data;
    },

  },
  actions: {
    async fetchMessageCommunication({getters, commit}) {
      try {
        return await getters.authenticatedAxios.get(GET_MESSAGE_COMMUNICATION_URL)
          .then((response) => commit('setMessagesCommunications', response.data));

      } catch (e) {
        return e;
      }
    },
    async updateMessageCommunication({getters}, message) {
      try {
        return await getters.authenticatedAxios.put(
          `${MESSAGE_COMMUNICATION_URL}/${message.id}`,
          message
        );
      } catch (e) {
        throw e;
      }
    }

  }

};
