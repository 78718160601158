import Vue from "vue";
import VueRouter from "vue-router";
import auth from "../services/auth";

const Home = () => import("../components/Home.vue");
const Map = () => import("../components/Map.vue");

const Welcome = () => import("../components/adherent/home/index.vue");

const update = () => import("../components/admin/update/index.vue");

const Adherents = () => import("../components/admin/adherents/Adherents");
const Accueil = () => import("../components/admin/page-accueil/Index");
const ShowEmail = () => import("../components/common/messagerie/ShowEmail");
const Attestations = () =>
    import("../components/admin/liste_attestations/Attestations");
const Organismes = () => import("../components/admin/organismes/Organismes");
const TypesAttestations = () =>
    import("../components/admin/types_attestations/TypesAttestations");
const TypePaiements = () =>
    import("../components/admin/type_paiements/TypePaiements");
const TypeDemandes = () =>
    import("../components/admin/type_demandes/TypeDemandes");
const Messagerie = () => import("../components/admin/messagerie/MessagerieList");
const Dashboard = () => import("../components/admin/dashboard/Dashboard");
const DeletedUsers = () => import("../components/admin/deleted-users/DeletedUserIndex");
const MessageCommunicationIndex = () => import("../components/admin/message-communication/MessageCommunicationIndex");
const Rectifier = () => import("../components/admin/Rectifier/Index");
 
const RapportsIndex = () => import("../components/admin/rapports/Rapports");
const Exercices = () => import("../components/admin/excercices/Excercices");
const Organisation = () => import("../components/admin/organisation/index");
const AdminDemandesIndex = () => import("../components/admin/demandes/DemandesIndex");
const AdminAttestationsIndex = () => import("../components/admin/adherents/attestations/AttestationsIndex");
const Utilisateurs = () =>
    import("../components/admin/administrations/utilisateurs/Utilisateurs");
const Journal = () => import("../components/admin/journal/Journal.vue");
const AdherentEditor = () =>
    import("../components/admin/adherents/AdherentEditor");
const UserDemandesIndex = () =>
    import("../components/admin/adherents/demandes/DemandesIndex");
const AdminUserVisitesIndex = () =>
    import("../components/admin/adherents/demandes/VisitesIndex");
const UserVisitesIndex = () =>
    import("../components/adherent/demandes-ouvertures/VisitesIndex");

const UserCommunicationIndex = () =>
    import("../components/adherent/communication/CommunicationIndex");
const AdherentCotisation = () =>
    import("../components/admin/adherents/paiement/PaiementIndex");
const AttestationsEditor = () =>
    import("../components/admin/liste_attestations/AttestationsEditor");
const UserEtatActivte = () =>
    import("../components/admin/adherents/etat-activites/EtatActivitesList");
const UtilisateursEditor = () =>
    import("../components/admin/administrations/utilisateurs/UtilisateursEditor");
const AdherentIndex = () => import("../components/adherent/UserSideBar");
const UserCotisations = () => import("../components/adherent/cotisation/CotisationsList");
const AdherentDashboard = () =>
    import("../components/adherent/dashboard/Index");
const AdherentProfil = () => import("../components/adherent/profil/ProfileFrom");
const DossierAdherant = () => import("../components/adherent/test/Test");
const AdherentDemandeOuverture = () =>
    import("../components/adherent/demandes-ouvertures/DemandesOuverturesIndex");
const AdherentAttestation = () =>
    import("../components/adherent/attestation/AttestationsListIndex");
const AdherentDocuments = () =>
    import("../components/adherent/documents/DocumentsListIndex");
const AdherentEtatActivite = () =>
    import("../components/adherent/etat-activite/EtatActivitesList");

const AdherentMessagerie = () =>
    import("../components/adherent/messagerie/MessagerieList");
const AdherentRegister = () => import("../components/adherent/auth/Register");
const AdherentLogin = () => import("../components/adherent/auth/Login");
const AdherentRegistrationSuccess = () =>
    import("../components/adherent/auth/RegistrationSuccess");
const ConfirmMail = () => import("../components/adherent/auth/ConfirmMail");
const ForgotPassword = () =>
    import("../components/adherent/auth/ForgotPassword");
const ResetPassword = () => import("../components/adherent/auth/ResetPassword");
const AdminLogin = () => import("../components/admin/auth/Login");
const DossiersRequis = () =>
    import("../components/admin/dossiers_requis/DossiersRequisLists");
const AdherantsCotisation = () =>
    import("../components/admin/historique_paiements/PaiamentsHistoryList");

Vue.use(VueRouter);

const routes = [
    {path: "/", component: Welcome,},
    {path: "/map", component: Map,},

    {
        path: "/adherent",
        component: AdherentIndex,
        breadcrumbName: "Adhérant",
        children: [
            {
                path: "/",
                redirect: "/adherent/dashboard"
            },
            {
                path: "dashboard",
                component: AdherentDashboard,
                meta: {requiresAuth: true, title: "Tableau de board"},
            },
            {
                path: "communication",
                component: UserCommunicationIndex,
                meta: {requiresAuth: true, title: "Communication"},
            },
            {
                path: "profil",
                component: AdherentProfil,
                meta: {requiresAuth: true, title: "Tableau de board"},
                breadcrumbName: "Profil",
            },
            {
                path: "attestations",
                component: AdherentAttestation,
                meta: {requiresAuth: true, title: "Demande attestations"},
                breadcrumbName: "Demande d'attestation",
            },
            {
                path: "demandes-ouvertures",
                component: AdherentDemandeOuverture,
                meta: {requiresAuth: true, title: "Demandes ouvertures"},
                breadcrumbName: "Demandes ouvertures",
            },
            {
                path: "visites/edit/:id(\\d+)",
                component: UserVisitesIndex,
                name: "users_visites",
                meta: {requiresAuth: true, title: "Mes visites"},
            },
            {
                path: "documents",
                component: AdherentDocuments,
                meta: {requiresAuth: true, title: "Mes Documents"},
                breadcrumbName: "Mes Documents",
            },
            {
                path: "cotisation",
                component: UserCotisations,
                meta: {requiresAuth: true},
                breadcrumbName: "Cotisations",
            },
            {
                path: "messagerie",
                component: AdherentMessagerie,
                meta: {requiresAuth: true, title: "Messagerie"},
                breadcrumbName: "Messagerie",
            },
            {
                path: "etat-activite",
                component: AdherentEtatActivite,
                meta: {requiresAuth: true, title: "Etat activites"},
                breadcrumbName: "Etat activites",
            },
            {
                path: "messagerie/show/:id/:isAdmin",
                component: ShowEmail,
                name: "show_email_adherant",
                meta: {requiresAuth: true},
            },
            {
                path: "test",
                component: DossierAdherant,
                name: "show_email_adherant1",
                meta: {requiresAuth: true},
            },
        ],
    },
    // Authentification
    {
        path: "/registration_success",
        component: AdherentRegistrationSuccess,
        name: "adherent_registration_success",
        meta: {requiresAuth: false},
    },
    {
        path: "/auth/signup/activate/:br",
        component: ConfirmMail,
        name: "adherent_confirm_email",
        meta: {requiresAuth: false},
    },

    {
        path: "/forgot_password",
        component: ForgotPassword,
        name: "adherent_forgot_password",
        meta: {requiresAuth: false, title: "Récuperation mot de passe"},
    },
    {
        path: "/password/reset/:token",
        component: ResetPassword,
        name: "adherent_reset_password",
        meta: {requiresAuth: false, title: "Réinitialisation de mot pass"},
    },
    {
        path: "/adherent/login/:op(success)?",
        name: "adherent_login",
        component: AdherentLogin,
        meta: {requiresAuth: false, title: "Login"},
    },
    {
        path: "/adherent/inscription",
        name: "adherent_inscription",
        component: AdherentRegister,
        meta: {requiresAuth: false, title: "Inscription"},
    },
    {
        path: "*",
        redirect: "/adherent/dashboard",
    },
    {
        path: "/admin/login",
        component: AdminLogin,
        name: "admin_login",
        meta: {requiresAuth: false, title: "Login"},
    },
    {
        path: "/admin",
        component: Home,
        breadcrumbName: "Admin",
        children: [
            {path: "/", redirect: "/admin/login"},
            {
                path: "dashboard",
                component: Dashboard,
                meta: {requiresAuth: true, title: "Tableau du board"},
            },
            {
                path: "remplir_etat_activite",
                component: update,
                meta: {requiresAuth: true, title: "Tableau du board"},
            },
            {
                path: "demandes",
                component: AdminDemandesIndex,
                meta: {requiresAuth: true, title: "Liste des visites"},
            },
            {
                path: "rapports",
                component: RapportsIndex,
                meta: {requiresAuth: true, title: "Liste des rapports"},
            },
            {
                path: "messages-communications",
                component: MessageCommunicationIndex,
                meta: {requiresAuth: true, title: "Messages communication"},
            },
            {
                path: "page-accueil",
                component: Accueil,
                meta: {requiresAuth: true, title: "Page accueil"},
            },
            {
                path: "adherents/:op(create|edit)/:id(\\d+)?",
                component: AdherentEditor,
                meta: {requiresAuth: true, title: "Edition adhérent"},
            },
            {
                path: "adherents/cotisation/edit/:id(\\d+)/:success(true|false)?",
                component: AdherentCotisation,
                meta: {requiresAuth: true, title: "Edition cotisation adhérent"},
            },
            {
                path: "adherents/attestations/:id(\\d+)",
                component: AdminAttestationsIndex,
                name: "user_attestations",
                meta: {requiresAuth: true, title: "Liste des attestations"},
            },
            {
                path: "adherents/etat-activites/edit/:id(\\d+)",
                component: UserEtatActivte,
                name: "user_etat_activite",
                meta: {requiresAuth: true, title: "Edition état actvites adhérent"},
            },
            {
                path: "adherents/demandes/edit/:id(\\d+)",
                component: UserDemandesIndex,
                name: "user_demandes",
                meta: {requiresAuth: true, title: "Edition visites adhérent"},
            },
            {
                path: "adherents/visites/edit/:id(\\d+)",
                component: AdminUserVisitesIndex,
                name: "user_visites",
                meta: {requiresAuth: true, title: "Edition visites adhérent"},
            },
            {
                path: "adherents",
                component: Adherents,
                meta: {requiresAuth: true, title: "Etat des inscrits"},
                breadcrumbName: "Liste des adhérants",
            },
            {
                path: "remplir_etat_paiement",
                component: Rectifier,
                meta: {requiresAuth: true, title: "Etat des inscrits"},
                breadcrumbName: "Liste des adhérants",
            },
            {
                path: "type_attestations",
                component: TypesAttestations,
                meta: {requiresAuth: true, title: "Types des attestations"},
                breadcrumbName: "Types des attestations",
            },
            {
                path: "type_paiements",
                component: TypePaiements,
                meta: {requiresAuth: true, title: "Types des paiements"},
                breadcrumbName: "Types des paiements",
            },
            {
                path: "type_demandes",
                component: TypeDemandes,
                meta: {requiresAuth: true, title: "Types des demandes"},
                breadcrumbName: "Types des demandes",
            },
            {
                path: "attestations/:op(create|edit)/:id(\\d+)?",
                component: AttestationsEditor,
                meta: {requiresAuth: true, title: "Edition types attestation"},
                breadcrumbName: "Admin",
            },
            {
                path: "attestations",
                component: Attestations,
                meta: {requiresAuth: true, title: "Attestations"},
                breadcrumbName: "Attestations",
            },
            {
                path: "organismes",
                component: Organismes,
                meta: {requiresAuth: true, title: "Organismes"},
                breadcrumbName: "Organismes",
            },
            {
                path: "organisation/edit",
                name: "organisation",
                component: Organisation,
                meta: {requiresAuth: true, title: "Paramètres organisation"},
                breadcrumbName: "Admin",
            },
            {
                path: "exercices",
                component: Exercices,
                meta: {requiresAuth: true, title: "Exercices"},
                breadcrumbName: "Exercices",
            },
            {
                path: "dossier_requis",
                component: DossiersRequis,
                meta: {requiresAuth: true, title: "Dossier requis"},
                breadcrumbName: "Dossier requis",
            },
            {
                path: "journal",
                component: Journal,
                name: "journal",
                meta: {requiresAuth: true},
                breadcrumbName: "Journal",
            },
            {
                path: "utilisateurs/:op(create|edit)/:id(\\d+)?",
                component: UtilisateursEditor,
                meta: {requiresAuth: true, title: "Edition utilisateur"},
            },
            {
                path: "utilisateurs",
                component: Utilisateurs,
                meta: {requiresAuth: true, title: "Utilisateurs"},
                breadcrumbName: "Utilisateurs",
            },
            {
                path: "cotisation",
                component: AdherantsCotisation,
                meta: {requiresAuth: true, title: "Liste des paiements"},
                breadcrumbName: "Cotisations",
            },
            {
                path: "deleted-users",
                component: DeletedUsers,
                meta: {requiresAuth: true, title: "Deleted users"},
                breadcrumbName: "Deleted users",
            },

            {
                path: "messagerie",
                component: Messagerie,
                name: "admin_emails",
                meta: {requiresAuth: true, title: "Messagerie"},
                breadcrumbName: "Messagerie",
            },

            {
                path: "messagerie/show/:id/:isAdmin",
                component: ShowEmail,
                name: "show_email_admin",
                meta: {requiresAuth: true},
            },
            {
                path: "*",
                redirect: "/admin/dashboard",
            },
        ],
    },
];
const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);
    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);
    // const previousNearestWithMeta = from.matched
    //   .slice()
    // .reverse()
    //   .find((r) => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(
        document.querySelectorAll("[data-vue-router-controlled]")
    ).map((el) => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    //  if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    if (nearestWithMeta) {
        nearestWithMeta.meta.metaTags
            .map((tagDef) => {
                const tag = document.createElement("meta");

                Object.keys(tagDef).forEach((key) => {
                    tag.setAttribute(key, tagDef[key]);
                });

                // We use this to track which meta tags we create, so we don't interfere with other ones.
                tag.setAttribute("data-vue-router-controlled", "");

                return tag;
            })
            // Add the meta tags to the document head.
            .forEach((tag) => document.head.appendChild(tag));
    }
    // redirect to login page if not logged in and trying to access a restricted page
    const user = auth.getUser();
    const isLoggedIn = auth.isLoggedIn();

    // for (const key in to.params) {
    //   const element = to.params[key];
    //   console.log(`Param key is  name ${key} value  ${element}`);
    // }
    // console.log(`Path is ${to.path}`);
    // console.log(`Full Path is ${to.fullPath}`);
    // console.log(`going To  : ${to.path.split("/")[1]}`);
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        let goingTo = to.path.split("/")[1];
        if (isLoggedIn) {
            if (goingTo == "admin" && user.is_admin) {
                //console.log(`Admin is logged in and passing `);
                next();
            } else if (goingTo == "adherent" && !user.is_admin) {
                // console.log(`Adherent is logged in and passing `);
                next();
            } else if (user.is_admin) {
                // console.error(`Admin is mixing role²s `);
                next("/admin/dashboard");
            } else if (!user.is_admin) {
                // console.error(`Adherent is mixing roles `);
                next("/adherent/dashboard");
            }
        } else {
            //console.warn(`Admin/Adherent is not logged in `);
            next({
                path: `/${goingTo}/login`,
                query: {returnUrl: to.fullPath},
            });
        }
    } else {
        //console.log(`Route that doent require  auth `);
        next(); // make sure to always call next()!
    }
});
export default router;
