 const COTISATION_URL = `/paiement`;
const COTISATION_FILE_URL = `/upload-paiement-documents`;

export default {
  state: {
    solde: null,
    paiements: [],
    user_selected_paiement:null,
    default_pagination: {
      current: 1,
      total: null,
      pageSize: 10,
    },
    pagination: {
      current: 1,
      total: null,
      pageSize: 10,
    },
  },
  getters: {
    getUserPaiementsPagination(state) {
      return {
        per_page: state.pagination.pageSize,
        page: state.pagination.current,
      };
    },
  },
  mutations: {
    setUserPaiements(state, data) {
      state.paiements = data;
    },
    setUserSolde(state, data) {
      state.solde = data.etat_paiement.balance;
    },
    setUserPaiementsPagination(state, data) {
      state.pagination = data;
    },
    setUserSelectedPaiement(state, data){
      state.user_selected_paiement = data;
    },
    setUserPaiementsDefaultPagination(state) {
      state.pagination = state.default_pagination;
    },
    setUserPaiementsPaginationFromServer(state, data) {
      state.pagination = {
        pageSize: Number(data.per_page),
        current: Number(data.current_page),
        total: Number(data.total),
      };
    },
  },

  actions: {
    async fetchUserPaiements({ dispatch, commit, getters }, payload) {
      try {
        let para = {
          ...payload,
          ...getters.getUserPaiementsPagination,
        };
        return await dispatch(
          "fetchUserPaiementsWithoutSettingState",
          para
        ).then((response) => {
          commit("setUserPaiements", response.data.data);
          commit("setUserSolde", response.data.data[0]);
          commit("setUserPaiementsPaginationFromServer", response.data.meta);
        });
      } catch (e) {
        throw e;
      }
    },
    async fetchUserPaiementById({ getters, commit }, id) {
    try {
        return await getters.authenticatedAxios
          .get(`${COTISATION_URL}/${id}`)
          .then((response) =>
            commit("setUserSelectedPaiement", response.data)
          );
      } catch (e) {
        return e;
      }
    },
    async storeUserPaiement({ getters }, paiement) {
      try {
        return await getters.authenticatedAxios.post(COTISATION_URL, paiement);
      } catch (e) {
        throw e;
      }
    },
    async destroyUserPaiement({ getters }, id) {
      try {
        return await getters.authenticatedAxios.delete(
          `${COTISATION_URL}/${id}`
        );
      } catch (e) {
        throw e;
      }
    },
      async destroyUserPaiementFile({ getters }, id) {
    try {
      return await getters.authenticatedAxios.delete(
        `${COTISATION_FILE_URL}/${Number(id)}`
      );
    } catch (e) {
      throw e;
    }
  },
    async updateUserPaiement({ getters }, paiement) {
      try {
        return await getters.authenticatedAxios.put(
          `${COTISATION_URL}/${paiement.id}`,
          paiement
        );
      } catch (e) {
        throw e;
      }
    },
    async fetchUserPaiementsWithoutSettingState({ getters }, payload) {
      try {
        return await getters.authenticatedAxios.get(`${COTISATION_URL}`, {
          params: {
            ...payload,
          },
        });
      } catch (e) {
        throw e;
      }
    },
  },
};