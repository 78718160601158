import Axios from "axios";
import message from "ant-design-vue/lib/message";
import authe from "../../services/auth";
import router from "../../router";

const ADMIN_LOGIN_URL = "/admin/login";
const ADHERENT_LOGIN_URL = "/login";
const REGISTER_URL = "/register";
const CONFIRMATION_URL = "/auth/signup/activate";
const FORGOT_PASSWORD = "/password/create";
const RESET_PASSWORD = "/password/reset";
const HTTP_UNAUTHORIZED = 401;
const HTTP_NOT_VALID = 422;

let BASE_URL = authe.getBaseUrl();


export default {
    state: {
        authenticated: JSON.parse(localStorage.getItem("user")),
        base_url: BASE_URL,
    },
    getters: {
        authenticatedAxios(state, getters) {
            let authorization = authe.getAuthorization();
            let axios = Axios.create({
                baseURL: `${BASE_URL}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authorization}`,
                },
                timeout: 30 * 1000,
                onUploadProgress: function () {},
            });
            axios.interceptors.response.use(
                function (response) {
                    message.destroy();
                    return response;
                },
                function (err) {
                    if (!err.response) {
                        message.loading("Connexion au serveur...", 5);
                        return;
                    }
                    if (err.response.status === HTTP_UNAUTHORIZED) {
                        let redirect = err.config.url.includes("/api/admin/")
                            ? "/admin/login"
                            : "/adherent/login";
                        router.push(redirect);
                        throw err;
                    }
                    if (err.response.status === HTTP_NOT_VALID) {
                        Object.keys(err.response.data.errors)
                            .forEach((field) => message.error(err.response.data.errors[field], 5))
                    }

                    return Promise.reject(err);
                }
            );
            return axios;
        },
        getAuthorization: (state) => {
            return localStorage.getItem("token");
        },
    },
    mutations: {
        setAuthenticated(state, payload) {
            localStorage.setItem("user", JSON.stringify(payload.user));
            localStorage.setItem("token", payload.token);
        },
        clearAuthentication(state) {
            localStorage.clear();
        },
    },
    actions: {
        async authenticate({commit}, payload) {
            let user = payload.user;
            const AUTH_URL =
                payload.role === "admin" ? ADMIN_LOGIN_URL : ADHERENT_LOGIN_URL;
            const axios = Axios.create({
                baseURL: BASE_URL,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            return await axios
                .post(AUTH_URL, user)
                .then(({data}) => {
                    commit("setAuthenticated", data.data);
                    if (payload.role !== "admin") {
                        commit("setAdherent", data.data.user);
                    }
                    return true;
                })
                .catch((error) => {
                    throw error;
                });
        },
        async register({getters}, user) {
            const axios = Axios.create({
                baseURL: BASE_URL,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });

            return await axios.post(REGISTER_URL, user);
        },
        async resetPassword(context, payload) {
            const axios = Axios.create({
                baseURL: BASE_URL,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            return await axios.post(RESET_PASSWORD, payload);
        },
        async confirmRegistration(context, token) {
            const axios = Axios.create({
                baseURL: BASE_URL,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });

            return await axios.get(`${CONFIRMATION_URL}/${token}`);
        },
        async forgotPassword(context, data) {
            const axios = Axios.create({
                baseURL: BASE_URL,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            return await axios.post(FORGOT_PASSWORD, data);
        },
    },
};
