const DOCUMENTS_URL = `/admin/document`

export default {
    state: {documents: []},
    mutations: {
        setDocuments(state, data) {
            state.documents = data.data
        }
    },
    actions:{
    async fetchDocuments({getters, commit}) {
        try {
            return await getters.authenticatedAxios.get(DOCUMENTS_URL)
                .then((response) => commit('setDocuments', response.data));

        } catch (e) {
            return e;
        }
    },  
        async storeDocument({getters}, document) {
            try {
                return await getters.authenticatedAxios.post(DOCUMENTS_URL, document);
            } catch (e) {
                throw e;
            }
        },
        async updateDocument({getters}, document) {
            try {
                return await getters.authenticatedAxios.put(
                    `${DOCUMENTS_URL}/${document.id}`,
                    document
                );
            } catch (e) {
                throw e;
            }
        },
        async destroyDocument({getters}, id) {
            try {
                return await getters.authenticatedAxios.delete(
                    `${DOCUMENTS_URL}/${id}`
                );
            } catch (e) {
                throw e;
            }
        },

    }

};
