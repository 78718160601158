const URL = `/commune`;

export default {
  state: {
    communes: [],
  },
  getters: {
    getCommunes(state) {
      return state.communes;
    },
  },
  mutations: {
    setCommunes(state, data) {
      state.communes = data;
    },
  },
  actions: {
    async fetchCommunes({ dispatch, commit }) {
      try {
        dispatch("fetchCommunesWithoutSettingState", URL).then((response) =>
          commit("setCommunes", response.data.data)
        );
      } catch (e) {
        throw e;
      }
    },
    async fetchCommunesWithoutSettingState({ getters }, url) {
      try {
        return await getters.authenticatedAxios.get(`${url}`, {
          params: {
            inSOR: true,
          },
        });
      } catch (e) {
        throw e;
      }
    },
  },
};
