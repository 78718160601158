const ADHERENT_URL = `/user`;
const ADHERENT_SOUMIS_URL = `/user/validate-pre-inscription`;
// const ETAT_ADHERANT_ACCEPTER = 2;
const PROFILE_PIC_URL = `/user/upload-profile-picture`;
export default {
  state: {
    adherent: null,
    etatsAdherents: [
      { key: 0, value: "En attente" },
      { key: 1, value: "Accepté" },
      { key: 2, value: "Refusé" },
    ],
  },
  getters: {
    getAdherent(state) {
      return state.adherent;
    },
    getListDossierRequis(state) {
      return state.adherent ? state.adherent.list_dossier : [];
    },
    getAdherentListOfProfilePhoto(state) {
      if (state.adherent && state.adherent.profile_picture) {
        return [
          {
            uid: "-1",
            name: state.adherent.profile_picture,
            status: "done",
            url: state.adherent.profile_picture_url,
          },
        ];
      }
      return [];
    },
    getUserEtatActivites(state) {
      return state.adherent
        ? state.adherent.etat_activites
        : [];
    },
  },
  mutations: {
    setAdherent(state, data) {
      state.adherent = data;
    },
    setAdherentListOfDossier(state, data) {
      state.adherent.listDossier = data.list_dossier;
      state.adherent = data;
    },
    resetAdherent(state) {
      state.adherent = null;
    },
  },
  actions: {
    async clearProfilePic({ getters }, id) {
      try {
        return await getters.authenticatedAxios.delete(
          `${PROFILE_PIC_URL}/${id}`
        );
      } catch (e) {
        throw e;
      }
    },
    async storeAdherent({ getters }, adherent) {
      try {
        return await getters.authenticatedAxios.post(ADHERENT_URL, adherent);
      } catch (e) {
        return e;
      }
    },
    async fetchAdherentListDossier({ dispatch, commit }) {
      try {
        dispatch("fetchAdherentWithoutSettingState").then((response) =>
          commit("setAdherentListOfDossier", response.data)
        );
      } catch (e) {
        return e;
      }
    },
    async fetchAdherent({ dispatch, commit }) {
      try {
        dispatch("fetchAdherentWithoutSettingState").then((response) => {
          commit("setAdherent", response.data);
          commit("setAdherentListOfDossier", response.data);
        });
      } catch (e) {
        return e;
      }
    },
    async fetchAdherentWithoutSettingState({ getters }) {
      try {
        return await getters.authenticatedAxios.get(ADHERENT_URL);
      } catch (e) {
        return e;
      }
    },
    async updatingAdherent({ getters }, adherant) {
      try {
        return await getters.authenticatedAxios.put(
          `${ADHERENT_URL}/${adherant.id}`,
          adherant
        );
      } catch (e) {
        throw e;
      }
    },
    async validateAndUpdateAdherentSoumissionState({ getters, dispatch }) {
      try {
        return await getters.authenticatedAxios
          .put(`${ADHERENT_SOUMIS_URL}`)
          .then(() => dispatch("fetchAdherent"));
      } catch (e) {
        throw e;
      }
    },
    async destroyAdherent({ getters }, adherent) {
      try {
        return await getters.authenticatedAxios.delete(
          `${ADHERENT_URL}/${adherent.id}`
        );
      } catch (e) {
        throw e;
      }
    },
  },
};
