const SECETEURS_URL = `/admin/secteur-activite`;
export default {
  state: {
    secteurs: [],
  },
  getters: {
    getSecteurs(state) {
      return state.secteurs;
    },
  },
  mutations: {
    setSecteurs(state, data) {
      state.secteurs = data;
    },
  },
  actions: {
    async fetchSecteurs({ getters, commit }) {
      try {
        return await getters.authenticatedAxios
          .get(`${SECETEURS_URL}`)
          .then((response) => commit("setSecteurs", response.data.data));
      } catch (e) {
        throw e;
      }
    },
  },
};
