const ORGANISATION_URL = `/admin/organisation`;
const ORGANISATION_ASSETS_URL = `/organisation_assets`;
import get from "lodash-es/get";
import auth from "@/services/auth";

export default {
  state: {
    organisation: null,
    listWilayas: [],
    listWilayasKeys: [],
    assets: {logo: null, bgImg: null}
  },
  getters: {
    getProfile(state) {
      return state.organisation;
    },
    getRegion(state) {
      return  get(  state,'organisation.wilaya.nom_fr','').toUpperCase();
    },
    getOrganisationLogo(state) {
      return state.assets.logo || get(state, 'organisation.file_url');
    },
    getOrganisationCCP(state) {
      return  get(  state,'organisation.ccp');
    },
    getOrganisationCompteBancaire(state) {
      return  get(  state,'organisation.compte_bancaire');
    },
    getOrganisationBgImg(state) {
      return   state.assets.bgImg || get(state, 'organisation.login_bg_file_url');
    },
    getSorWilaysKeys(state) {
      return state.listWilayasKeys.map(wilaya => {
        return { wilaya: { id: wilaya, code: null, name: null } };
      });
    },
    getOrganisationAllowsAderial(state) {
      return (state.organisation.aderial_id != null && state.organisation.aderial_key != null);
    },
  },
  mutations: {
    setOrganisation(state, data) {
      state.organisation = data;
    },
    setSorWilayas(state, data) {
      state.listWilayas = data.map(item => item.wilaya);
    },
    // The already selected wilayas keys
    setSorSelectedWilaysKeys(state, data) {
      state.listWilayasKeys = data.map(item => item.wilaya.id);
    },
    // The  Currently already selected wilayas keys
    setSorWilaysKeys(state, data) {
      state.listWilayasKeys = data;
    },
    setOrganisationAssets(state, data) {
      state.assets = data;
    }
  },
  actions: {
    async fetchOrganisation({ dispatch, commit }) {
      try {
        return await dispatch(
            "fetchOrgansiationWithoutSettingState"
        ).then(response => commit("setOrganisation", response.data));
      } catch (e) {
        return e;
      }
    },
    async fetchOrganisationLogoAndLoginBackgroundImage({  commit }) {
      try {
        return await auth.axios().get(`${ORGANISATION_ASSETS_URL}`)
        .then(response => commit("setOrganisationAssets", response.data.data));
      } catch (e) {
        return e;
      }
    },
    async fetchOrgansiationWithoutSettingState({getters}) {
      try {
        return await getters.authenticatedAxios.get(ORGANISATION_URL);
      } catch (e) {
        return e;
      }
    },
    async updateOrganisation({getters}, profile) {
      try {
        return await getters.authenticatedAxios.put(
          `${ORGANISATION_URL}/${profile.id}`,
          profile
        );
      } catch (e) {
        throw e;
      }
    }
  }
};
