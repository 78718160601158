import Axios from "axios";

export default {
  isLoggedIn: () => {
    return !!localStorage.getItem("token");
  },
  getAuthorization: getAuthorization,
  getUser: () => {
    return JSON.parse(window.localStorage.getItem("user"));
  },
  getUserId: () => {
    return JSON.parse(window.localStorage.getItem("user")).id;
  },
  getBaseUrl: getBaseUrl,
  axios: () => {
    return Axios.create({
      baseURL: getBaseUrl(),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAuthorization()}`,
      },
    });
  },
};
function getBaseUrl() {
  return (
    process.env.VUE_APP_ROOT_API_HTTP + window.location.hostname
    + process.env.VUE_APP_ROOT_API_PORT 
  );
}
function getAuthorization() {
  return localStorage.getItem("token");
}
