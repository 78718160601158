const ATTESTATIONS_URL = `/user/attestation`;

export default {
  state: {
    adherant_attestations: [],
  },
  getters: {},
  mutations: {
    setAdherentAttestations(state, data) {
      state.adherant_attestations = data;
    },
  },
  actions: {
    async fetchAdherentAttestations({ getters, commit }, payload) {
      try {
        return getters.authenticatedAxios
          .get(ATTESTATIONS_URL, {
            params: {
              ...payload,
            },
          })
          .then((response) =>
            commit("setAdherentAttestations", response.data.data)
          );
      } catch (e) {
        throw e;
      }
    },
    async storeAdherentAttestations({ getters }, attestation) {
      try {
        return await getters.authenticatedAxios.post(
          ATTESTATIONS_URL,
          attestation
        );
      } catch (e) {
        throw e;
      }
    },
    async updateAdherentAttestation({ getters }, attestation) {
      try {
        return await getters.authenticatedAxios.put(
          `${ATTESTATIONS_URL}/${attestation.id}`,
          attestation
        );
      } catch (e) {
        throw e;
      }
    },
    async destroyAdherentAttestation({ getters }, attestationId) {
      try {
        return await getters.authenticatedAxios.delete(
          `${ATTESTATIONS_URL}/${attestationId}`
        );
      } catch (e) {
        throw e;
      }
    },
  },
};
