const ORGANISME_URL = `/organisme`;

export default {
  state: {
    organismes: [],
  },
  getters: {
    getAdherentOrganismes(state) {
      return state.organismes;
    },
  },
  mutations: {
    setAdherentOrganismes(state, data) {
      state.organismes = data;
    },
  },
  actions: {
    async fetchAdherentOrganismes({ commit, dispatch }) {
      try {
        return dispatch(
          "fetchAdherentOrganismesWithoutSettingState"
        ).then((response) =>
          commit("setAdherentOrganismes", response.data.data)
        );
      } catch (e) {
        return e;
      }
    },
    async fetchAdherentOrganismesWithoutSettingState({ getters }) {
      try {
        return await getters.authenticatedAxios.get(ORGANISME_URL);
      } catch (e) {
        return e;
      }
    },
  },
};
