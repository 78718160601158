const UTILISATEURS_URL = `/admin/users`;

export default {
  state: {
    utilisateurs: [],
  },
  getters: {
    getUtilisateurs(state) {
      return state.utilisateurs;
    },
    getUtilisateurById(state) {
      return (id) => {
        return state.utilisateurs.find(
          (utilisateur) => utilisateur.userId == id
        );
      };
    },
  },
  mutations: {
    setUtilisateurs(state, data) {
      state.utilisateurs = data;
    },
    filteredListOfUtilisateurs(state, value) {
      state.utilisateurs = state.utilisateurs.filter((utilisateur) => {
        return (
          utilisateur.userName.toLowerCase().includes(value.toLowerCase()) ||
          utilisateur.fullName.toLowerCase().includes(value.toLowerCase())
        );
      });
    },
  },
  actions: {
    async storeUtilisateur({ getters, dispatch }, utilisateur) {
      // 201
      try {
        return await getters.authenticatedAxios
          .post(UTILISATEURS_URL, utilisateur)
          .then(() => dispatch("fetchUtilisateurs"));
      } catch (e) {
        throw e;
      }
    },
    async fetchUtilisateurs({ getters, commit }) {
      try {
        return await getters.authenticatedAxios
          .get(`${UTILISATEURS_URL}`)
          .then((response) => commit("setUtilisateurs", response.data));
      } catch (e) {
        throw e;
      }
    },

    async updateUtilisateur({ getters, dispatch }, utilisateur) {
      try {
        return await getters.authenticatedAxios
          .put(`${UTILISATEURS_URL}/${utilisateur.userId}`, utilisateur)
          .then(() => dispatch("fetchUtilisateurs"));
      } catch (e) {
        throw e;
      }
    },
    async destroyUtilisateur({ getters, dispatch }, id) {
      try {
        return await getters.authenticatedAxios
          .delete(`${UTILISATEURS_URL}/${id}`)
          .then(() => dispatch("fetchUtilisateurs"));
      } catch (e) {
        throw e;
      }
    },
    async filteredListOfUtilisateurs({ commit, dispatch }, value) {
      if (value === "") {
        return dispatch("fetchUtilisateurs").then(() =>
          commit("filteredListOfUtilisateurs", value)
        );
      }
      commit("filteredListOfUtilisateurs", value);
    },
  },
};
