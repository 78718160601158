const RAPPORTS_URL = `/admin/rapport`;
export default {
    state: {
        rapports: [],
    },
    getters: {
        getTypeRapports(state) {
            return state.rapports;
        },
    },
    mutations: {
        setRapports(state, data) {
            state.rapports = data;
        },
    },
    actions: {
        async fetchRapports({getters, commit}, payload) {
            try {
                return await getters.authenticatedAxios.get(RAPPORTS_URL, {
                    params: {
                        ...payload,
                    },
                })
                    .then((response) => commit("setRapports", response.data.data));
            } catch (e) {
                return e;
            }
        },
        async updateRapport({getters}, rapport) {
            try {
                return await getters.authenticatedAxios.put(
                    `${RAPPORTS_URL}/${rapport.id}`,
                    rapport
                );
            } catch (e) {
                throw e;
            }
        },
    },
};
