const TYPE_DEMANDES_URL = `/type-demandes`;
export default {
    state: {
        type_demandes: [],
    },
    getters: {
        getTypeDemandes(state) {
            return state.type_demandes;
        },
    },
    mutations: {
        setTypeDemandes(state, data) {
            state.type_demandes = data;
        },
    },
    actions: {
        async fetchTypeDemandes({getters, commit}, payload) {
            try {
                return await getters.authenticatedAxios.get(TYPE_DEMANDES_URL, {
                    params: {
                        ...payload,
                    },
                })
                    .then((response) => commit("setTypeDemandes", response.data.data));
            } catch (e) {
                return e;
            }
        },
        async updateTypeDemandes({getters}, typeDemande) {
            try {
                return await getters.authenticatedAxios.put(
                    `${TYPE_DEMANDES_URL}/${typeDemande.id}`,
                    typeDemande
                );
            } catch (e) {
                throw e;
            }
        },
        async storeTypeDemande({getters}, typeDemande) {
            try {
                return await getters.authenticatedAxios.post(
                    `${TYPE_DEMANDES_URL}`,
                    typeDemande
                );
            } catch (e) {
                throw e;
            }
        },
        async destroyTypeDemande({getters}, typeDemande) {
            try {
                return await getters.authenticatedAxios.delete(
                    `${TYPE_DEMANDES_URL}/${typeDemande.id}`
                );
            } catch (e) {
                throw e;
            }
        },
    },
};
