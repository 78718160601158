import Vue from "vue";
import Vuex from "vuex";
import createCache from "vuex-cache";

import Adherents from "./admin/adherents";
import Attestations from "./admin/attestations";
import Utilisateurs from "./admin/utilisateurs";
import Organismes from "./admin/organismes";
import Dashboard from "./admin/dashboard";
import AdminPaiements from "./admin/paiement";
import Organisation from "./admin/organisation";
import Reglement from "./admin/reglement";
import Statistiques from "./admin/statistiques";
import Membres from "./admin/membres";
import Events from "./admin/events";
import Rapports from "./admin/rapports";
import Contacts from "./admin/contacts";
import Logs from "./admin/log";
import AdminEtatActivites from "./admin/admin_etat_activites";
import MessagesCommunication from "./admin/messages_communication";
import Accueil from "./admin/accueil";
import Documents from "./admin/documents";


import AdherantAttestations from "./adherent/adherent_attestations";
import TypeAttestations from "./common/type_attestation";
import TypeDemandes from "./common/type_demandes";
import Adherent from "./adherent/profile";
import UserPaiement from "./adherent/paiement";
import AdherentOrganisme from "./adherent/organismes";
import AdherentReglement from "./adherent/reglement";
import Demandes from "./common/demandes";
import Visites from "./common/visites";
import EtatActivite from "./adherent/etat_activites";
//___________________Common
import DossiersRequis from "./common/dossiers_requis";
import Wilayas from "./common/wilayas";
import Specialites from "./common/specialites";
import Communes from "./common/communes";
import Diplomes from "./common/diplomes";
import Authentification from "./common/auth";
import DocumentsGeneration from "./common/printing_docs";
import Secteurs from "./common/secteurs";
import Status from "./common/status";
import Messagerie from "./common/messagerie";
import Exercice from "./common/exercice";
import ModeReglement from "./common/mode_reglement";
import TypePaiements from "./common/type_paiements";
import TypeVisites from "./common/type_visites";
import SpecialiteMedecin from "./common/specialite_medecin";

Vue.use(Vuex);
Vue.config.devtools = true;

// initial state
let initialState = {
  adherents: Adherents.state,
  adherant_attestations: AdherantAttestations.state,
  types_attestations: TypeAttestations.state,
  attestations: Attestations.state,
  membres: Membres.state,
  events : Event.state,
  contacts : Contacts.state,
  utilisateurs: Utilisateurs.state,
  dossiers_requis: DossiersRequis.state,
  messages_communications: MessagesCommunication.state,
  accueil: Accueil.state,
  documents : Documents.state,
  wilayas: Wilayas.state,
  auth: Authentification.state,
  generate_documents: DocumentsGeneration.state,
  adherent: Adherent.state,
  user_paiement: UserPaiement.state,
  adherent_organismes: AdherentOrganisme.state,
  adherent_reglement: AdherentReglement.state,
  etat_activites: EtatActivite.state,
  demandes: Demandes.state,
  visites: Visites.state,

  statistiques: Statistiques.state,
  secteurs: Secteurs.state,
  type_demandes: TypeDemandes.state,
  type_visites: TypeVisites.state,
  specialites_medecin: SpecialiteMedecin.state,
  statuts: Status.state,
  messageries: Messagerie.state,
  dashboard: Dashboard.state,
  organismes: Organismes.state,
  rapports: Rapports.state,
  specialites: Specialites.state,
  diplomes: Diplomes.state,
  communes: Communes.state,
  mode_reglement: ModeReglement.state,
  type_paiements: TypePaiements.state,
  exercice: Exercice.state,
  logs: Logs.state,
  admin_paiements: AdminPaiements.state,
  organisation: Organisation.state,
  reglement: Reglement.state,
  admin_etat_activites: AdminEtatActivites.state,
};

export default new Vuex.Store({
  strict: false,
  plugins: [ createCache()],
  modules: {
    adherents: Adherents,
    types_attestations: TypeAttestations,
    attestations: Attestations,
    membres: Membres,
    events : Events,
    documents : Documents,
    contacts : Contacts,
    adherant_attestations: AdherantAttestations,
    messages_communications: MessagesCommunication,
    accueil: Accueil,
    demandes: Demandes,
    visites: Visites,
    utilisateurs: Utilisateurs,
    organismes: Organismes,
    wilayas: Wilayas,
    auth: Authentification,
    adherent: Adherent,
    user_paiement: UserPaiement,
    adherent_organismes: AdherentOrganisme,
    adherent_reglement: AdherentReglement,
    etat_activites: EtatActivite,
    specialites_medecin: SpecialiteMedecin,
    statistiques: Statistiques,
    rapports: Rapports,
    secteurs: Secteurs,
    statuts: Status,
    specialites: Specialites,
    communes: Communes,
    mode_reglement: ModeReglement,
    type_paiements: TypePaiements,
    type_demandes: TypeDemandes,
    type_visites: TypeVisites,
    exercices: Exercice,
    diplomes: Diplomes,
    messageries: Messagerie,
    dashboard: Dashboard,
    generate_documents: DocumentsGeneration,
    dossiers_requis: DossiersRequis,
    admin_paiements: AdminPaiements,
    admin_etat_activites: AdminEtatActivites,

    organisation: Organisation,
    reglement: Reglement,
    logs: Logs,
  },
  state: {},
  getters: {},
  mutations: {
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], initialState[key]);
      });
      state.adherent.adherent = null;
      this.commit("resetAdherent");
    },
  },
  actions: {},
});
