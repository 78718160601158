const URL = `/diplome`;

export default {
  state: {
    diplomes: [],
  },
  getters: {
    getDiplomes(state) {
      return state.diplomes;
    },
  },
  mutations: {
    setDiplomes(state, data) {
      state.diplomes = data;
    },
  },
  actions: {
    async fetchDiplomes({ dispatch, commit }) {
      try {
        dispatch("fetchDiplomesWithoutSettingState", URL).then((response) =>
          commit("setDiplomes", response.data.data)
        );
      } catch (e) {
        throw e;
      }
    },
    async fetchDiplomesWithoutSettingState({ getters }, url) {
      try {
        return await getters.authenticatedAxios.get(url);
      } catch (e) {
        throw e;
      }
    },
  },
};
