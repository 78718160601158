const STATUS_URL = `/admin/statut-professionnel`;
export default {
  state: {
    statuts: [
      { id: 1, nom_fr: "Actif(ve)" , color : "#52c41a", icon : "check-circle"},
      { id: 2, nom_fr: "Arrêt d'activité (Temporaire)", color : "#eb2f96", icon : "close-circle" },
      { id: 3, nom_fr: "Retraité(e)", color : "", icon : "clock-circle" },
      { id: 4, nom_fr: "Arrêt (Définitif)", color : "#eb2f96", icon : "check-circle" },
    ],
  },
  getters: {
    getStatuts(state) {
      return state.statuts;
    },
  },
  mutations: {
    setStatuts(state, data) {
      state.statuts = data;
    },
  },
  actions: {
    async fetchStatuts({ getters, commit }) {
      try {
        return await getters.authenticatedAxios
          .get(`${STATUS_URL}`)
          .then((response) => commit("setStatuts", response.data.data));
      } catch (e) {
        throw e;
      }
    },
  },
};
