const TYPE_ATTESTATIONS_URL = `/admin/type-attestation`;
const ADMIN_TYPE_ATTESTATIONS_URL = `/admin/type-attestation`;
export default {
  state: {
    type_attestations: [],
  },
  getters: {
    getTypeAttestations(state) {
      return state.type_attestations;
    },
  },
  mutations: {
    setTypeAttestations(state, data) {
      state.type_attestations = data.sort((a, b) => {
        return b.id - a.id;
      });
    },
    updateTypeAttestations(state, data) {
      state.type_attestations = state.type_attestations.filter(
        (attestation) => attestation.id !== data.id
      );
      state.type_attestations.push(data);
    },
  },
  actions: {
    async storeTypeAttestation({ getters }, attestation) {
      try {
        return await getters.authenticatedAxios.post(
          ADMIN_TYPE_ATTESTATIONS_URL,
          attestation
        );
      } catch (e) {
        return e;
      }
    },
    async fetchTypeAttestations({ getters, commit }, payload) {
      try {
        return await getters.authenticatedAxios
          .get(TYPE_ATTESTATIONS_URL, {
            params: {
              ...payload,
            },
          })
          .then((response) =>
            commit("setTypeAttestations", response.data.data)
          );
      } catch (e) {
        return e;
      }
    },
    async updateTypeAttestation({ getters, commit }, attestation) {
      try {
        return await getters.authenticatedAxios
          .put(`${ADMIN_TYPE_ATTESTATIONS_URL}/${attestation.id}`, attestation)
          .then((response) => commit("updateTypeAttestations", response.data));
      } catch (e) {
        return e;
      }
    },
    async destroyTypeAttestation({ getters }, attestation) {
      try {
        return await getters.authenticatedAxios.delete(
          `${ADMIN_TYPE_ATTESTATIONS_URL}/${attestation.id}`,
          attestation
        );
      } catch (e) {
        throw e;
      }
    },
  },
};
