const MEMBRES_URL = `/admin/membre`;

export default {
    state: {
        membres: [],
    },
    getters: {},
    mutations: {
        setMembres(state, data) {
            state.membres = data;
        },
    },
    actions: {
        async fetchMembres({getters, commit}) {
            try {
                return await getters.authenticatedAxios.get(MEMBRES_URL)
                    .then(response => commit("setMembres", response.data.data));
            } catch (e) {
                return e;
            }
        },
        async storeMembre({getters}, reglement) {
            try {
                return await getters.authenticatedAxios.post(MEMBRES_URL, reglement);
            } catch (e) {
                throw e;
            }
        },
        async updateMembre({getters}, membre) {
            try {
                return await getters.authenticatedAxios.put(
                    `${MEMBRES_URL}/${membre.id}`,
                    membre
                );
            } catch (e) {
                throw e;
            }
        },
        async destroyMembre({getters}, id) {
            try {
                return await getters.authenticatedAxios.delete(
                    `${MEMBRES_URL}/${id}`
                );
            } catch (e) {
                throw e;
            }
        },

    },
};
