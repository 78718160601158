const VISITE_URL = `/visite`;
const VISITE_OF_DEMENDES_URL = `/visites-demande`;
// const DEMANDE_OUVERTURES_COUNT__URL = `/demande/new-demande-count`;
// const VISITE_RAPPORT_URL = `/admin/rapport-visite`;
const DELETE_VISITE_FILE_URL = `/upload-visite-documents`;
// const DEMANDE_OUVERTURES_UPDAPTE_ETAT = `demande/update-etat`;


export default {
    state: {
        visites: [],

    },
    getters: {
        getVisites(state) {
            return state.visites;
        },
    },
    mutations: {
        setVisites(state, data) {
            state.visites = data;
        },
    },
    actions: {
        async fetchVisites({commit, dispatch}, payload) {
            try {
                return dispatch(
                    "fetchVisitesWithoutSettingState", payload
                ).then((response) =>
                    commit("setVisites", response.data.data)
                );
            } catch (e) {
                return e;
            }
        },
        async fetchVisiteById({commit, getters}, id) {
            try {
                return await getters.authenticatedAxios.get(
                    `${VISITE_URL}/${id}`
                );
            } catch (e) {
                return e;
            }
        },
        async storeVisite({getters}, payload) {
            try {
                return await getters.authenticatedAxios.post(
                    `${VISITE_URL}`,
                    payload
                );
            } catch (e) {
                return e;
            }
        },
        async updateVisite({getters, commit}, payload) {
            try {
                return await getters.authenticatedAxios
                    .put(`${VISITE_URL}/${payload.id}`, payload);
            } catch (e) {
                return e;
            }
        },
        async destroyVisite({getters}, id) {
            try {
                return await getters.authenticatedAxios
                    .delete(`${VISITE_URL}/${id}`);
            } catch (e) {
                return e;
            }
        },      async deleteVisiteFile({getters}, id) {
            try {
                return await getters.authenticatedAxios
                    .delete(`${DELETE_VISITE_FILE_URL}/${id}`);
            } catch (e) {
                return e;
            }
        },
        async fetchVisitesOfDemandeById({commit, getters}, payload) {
            try {
                return await getters.authenticatedAxios.get(`${VISITE_OF_DEMENDES_URL}/${payload.id}` )
                    .then((response) => commit("setVisites", response.data.data));
            } catch (e) {
                return e;
            }
        },
        async fetchVisitesWithoutSettingState({getters}, payload) {
            try {
                return await getters.authenticatedAxios.get(VISITE_URL, {
                    params: {
                        ...payload,
                    },
                });
            } catch (e) {
                return e;
            }
        },
    },
};