const ETAT_ACTIVITE_URL = `/user/etat-activite`;
const DELETE_ETAT_ACTIVITE_FILE_URL = `/etat-activite/upload-documents`;
export default {
  state: {
    etatActivites: [],
  },
  mutations: {
    setUserEtatActivites(state, data) {
      state.etatActivites = data;
    },
  },
  actions: {
    async destroyUserEtatActiviteFile({ getters }, etatActivite) {
      try {
        return await getters.authenticatedAxios.delete(
          `${DELETE_ETAT_ACTIVITE_FILE_URL}/${etatActivite}`
        );
      } catch (e) {
        return e;
      }
    },
    async destroyUserEtatActivite({ getters }, etatActivite) {
      try {
        return await getters.authenticatedAxios.delete(
          `${ETAT_ACTIVITE_URL}/${etatActivite}`
        );
      } catch (e) {
        return e;
      }
    },
    async updateUserEtatActvite({ getters }, etatActivite) {
      try {
        return await getters.authenticatedAxios.put(
          `${ETAT_ACTIVITE_URL}/${etatActivite.id}`,
          etatActivite
        );
      } catch (e) {
        throw e;
      }
    },
    async storeUserEtatActivites({ getters }, etatActivite) {
      try {
        return await getters.authenticatedAxios.post(
          ETAT_ACTIVITE_URL,
          etatActivite
        );
      } catch (e) {
        return e;
      }
    },
    async fetchUserEtatActivites({ dispatch, commit }) {
      try {
        return await dispatch(
          "fetchUserEtatActivitesWithoutSettingState"
        ).then((response) =>
          commit("setUserEtatActivites", response.data.data)
        );
      } catch (e) {
        return e;
      }
    },
    async fetchUserEtatActivitesWithoutSettingState(context) {
      try {
        return await context.getters.authenticatedAxios.get(
          `${ETAT_ACTIVITE_URL}`
        );
      } catch (e) {
        return e;
      }
    },
  },
};
