const EVENTS_URL = `/admin/event`;
const EVENTS_IMAGES_URL = `/admin/event-logo`

export default {
    state: {
        events: [],
    },
    getters: {},
    mutations: {
        setEvents(state, data) {
            state.events = data;
        },
    },
    actions: {
        async fetchEvents({getters, commit}) {
            try {
                return await getters.authenticatedAxios.get(EVENTS_URL)
                    .then(response => commit("setEvents", response.data.data));
            } catch (e) {
                return e;
            }
        },
        async storeEvent({getters}, event) {
            try {
                return await getters.authenticatedAxios.post(EVENTS_URL, event);
            } catch (e) {
                throw e;
            }
        },
        async updateEvent({getters}, event) {
            try {
                return await getters.authenticatedAxios.put(
                    `${EVENTS_URL}/${event.id}`,
                    event
                );
            } catch (e) {
                throw e;
            }
        },
        async destroyEvent({getters}, id) {
            try {
                return await getters.authenticatedAxios.delete(
                    `${EVENTS_URL}/${id}`
                );
            } catch (e) {
                throw e;
            }
        },
        async destroyEventImage({getters}, {id, image_idx}) {
            try {
                return await getters.authenticatedAxios.delete(
                  `${EVENTS_IMAGES_URL}/${id}`,
                  { data: { image_idx } }
                );
            } catch (e) {
                throw e;
            }
        },

    },
};
