const STATS_URL = `/admin/stats`;
const COUNTERS_URL = `/admin/stats/counters`;
const HOMME = 0;

import _forEach from "lodash-es/forEach";
import _uniqBy from "lodash-es/uniqBy";

export default {
  state: {
    stats: {
      stats: [],
      labels: [],
      values: {
        femmes: [],
        hommes: [],
      },
      paiement_labels: ["Paye", "Non paye"],
      paiement_stats: [0, 0],
    },
    wilayas: {},
    counters: {
      refuser: 0,
      en_attente: 0,
      acceptes: 0,
    },
  },
  getters: {
    getStats(state) {
      return state.stats;
    },
    getFemmesStats(state) {
      let a = [];
      _forEach(state.wilayas, function(value, key) {
        a.push(value["femmes"]);
      });
      return a;
    },
    getHommesStats(state) {
      let a = [];
      _forEach(state.wilayas, function(value, key) {
        a.push(value["hommes"]);
      });
      return a;
    },
  },
  mutations: {
    setPaiementStats(state, data) {
      state.stats.paiement_stats = [];
      state.stats.paiement_stats.push(data.paied);
      state.stats.paiement_stats.push(data.not_paied);
    },
    setCounters(state, data) {
      _forEach(data, function(value, key) {
        state.counters[value.label] = value.value;
      });
    },
    setSexeStats(state, data) {
      state.stats.labels = [];
      let labels = [];
      state.wilayas = {};
      for (let i = 0; i < data.length; i++) {
        labels.push(data[i].wilaya);
      }
      state.stats.labels = _uniqBy(labels);

      _forEach(state.stats.labels, function(value, key) {
        state.wilayas[value] = { hommes: 0, femmes: 0 };
      });

      _forEach(data, function(value, key) {
        if (value.sexe === HOMME) {
          state.wilayas[value.wilaya]["hommes"] = value.value;
        } else {
          state.wilayas[value.wilaya]["femmes"] = value.value;
        }
      });
    },
  },
  actions: {
    async fetchStats({ getters, commit }, payload) {
      try {
        let filter_by = payload.filter_by;
        return await getters.authenticatedAxios
          .get(`${STATS_URL}`, {
            params: { ...payload },
          })
          .then((response) => {
            if (filter_by == "sexe") {
              commit("setSexeStats", response.data[0]);
              return;
            }
            commit("setPaiementStats", response.data[0]);
          });
      } catch (e) {
        throw e;
      }
    },
    async fetchCounters({ getters, commit }) {
      try {
        return await getters.authenticatedAxios
          .get(`${COUNTERS_URL}`)
          .then((response) => {
            commit("setCounters", response.data);
          });
      } catch (e) {
        throw e;
      }
    },
  },
};
