const URL = `/specialite_medecin`;
export default {
    state: {
        specialites_medecin: [],
    },
    getters: {
        getSpecialitesMedecin(state) {
            return state.specialites_medecin;
        },
    },
    mutations: {
        setSpecialitesMedecin(state, data) {
            state.specialites_medecin = data;
        },
    },
    actions: {
        async fetchSpecialitesMedecin({dispatch, commit}) {
            try {
                return dispatch(
                    "fetchSpecialitesMedecinWithoutSettingState",
                    URL
                ).then((response) => commit("setSpecialitesMedecin", response.data.data));
            } catch (e) {
                throw e;
            }
        },
        async fetchSpecialitesMedecinWithoutSettingState({getters}, url) {
            try {
                return await getters.authenticatedAxios.get(url);
            } catch (e) {
                throw e;
            }
        },
    },
};
