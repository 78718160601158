// const ADMIN_MODE_REGLMENT_URL = `/admin/modes`;
// const ADHERENT_MODE_REGLMENT_URL = `/modes`;
// const FROM_ADMIN = 0;

export default {
  state: {
    modes_reglement: [
      { id: 1, code: "ESP", name: "Espèce" },
      { id: 2, code: "CHEQ", name: "Chèque" },
      { id: 3, code: "VRM", name: "Virement" },
      { id: 4, code: "ELG", name: "En Ligne" },
    ],
  },
  getters: {
    getModesReglement(state) {
      return state.modes_reglement;
    },
  },
  mutations: {
    setModesReglement(state, data) {
      state.modes_reglement = data;
    },
  },
  actions: {
    async fetchModesReglement({ dispatch, commit }, payload) {

    },
    async fetchModesReglementWithoutSettingState({ getters }, url) {
      try {
        return await getters.authenticatedAxios.get(url);
      } catch (e) {
        throw e;
      }
    },
  },
};
