const TYPE_PAIEMENTS_URL = "admin/type-reglement";
export default {
  state: {
    type_paiements: [],
    default_pagination: {
      current: 1,
      total: 0,
      pageSize: 10,
    },
    pagination: {
      current: 1,
      total: 0,
      pageSize: 10,
    },
  },
  getters: {
    getTypePaiements(state) {
      return state.type_paiements;
    },
    getTypePaiementPagination(state) {
      return {
        per_page: state.pagination.pageSize,
        page: state.pagination.current,
      };
    },
  },
  mutations: {
    setTypePaiements(state, data) {
      state.type_paiements = data;
    },
    setTypePaiementsPagination(state, data) {
      state.pagination = data;
    },
    setTypePaiementsDefaultPagination(state) {
      state.pagination = state.default_pagination;
    },
    setTypePaiementsPaginationFromServer(state, data) {
      state.pagination = {
        pageSize: Number(data.per_page),
        current: Number(data.current_page),
        total: Number(data.total),
      };
    },
  },
  actions: {
    async fetchTypePaiementsWithoutPagination({ dispatch, commit }, payload) {
      try {
        return await dispatch("fetchTypePaiementsWithoutSettingState", {
          ...payload,
        }).then((response) => {
          commit("setTypePaiements", response.data.data);
          commit("setTypePaiementsPaginationFromServer", response.data.meta);
        });
      } catch (e) {
        throw e;
      }
    },
    async fetchTypePaiements({ dispatch, commit, getters }, data) {
      try {
        let payload = {
          ...data,
          ...getters.getTypePaiementPagination,
        };
        return await dispatch(
          "fetchTypePaiementsWithoutSettingState",
          payload
        ).then((response) => {
          commit("setTypePaiements", response.data.data);
          commit("setTypePaiementsPaginationFromServer", response.data.meta);
        });
      } catch (e) {
        throw e;
      }
    },
    async storeTypePaiement({ getters }, typePaiement) {
      try {
        return await getters.authenticatedAxios.post(
          TYPE_PAIEMENTS_URL,
          typePaiement
        );
      } catch (e) {
        throw e;
      }
    },
    async updateTypePaiement({ getters }, typePaiement) {
      try {
        return await getters.authenticatedAxios.put(
          `${TYPE_PAIEMENTS_URL}/${typePaiement.id}`,
          typePaiement
        );
      } catch (e) {
        throw e;
      }
    },
    async destroyTypePaiement({ getters }, id) {
      try {
        return await getters.authenticatedAxios.delete(
          `${TYPE_PAIEMENTS_URL}/${id}`
        );
      } catch (e) {
        throw e;
      }
    },
    async fetchTypePaiementsWithoutSettingState({ getters }, payload) {
      try {
        return await getters.authenticatedAxios.get(TYPE_PAIEMENTS_URL, {
          params: {
            ...payload,
          },
        });
      } catch (e) {
        throw e;
      }
    },
  },
};
