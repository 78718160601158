import Axios from "axios";
import get from "lodash-es/get";

const PRINT_URL = process.env.VUE_APP_PDF_GENRATOR_URL;
const DOWNLOAD_MODE = `1`;

export default {
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async callPdfGenerator({}, payload) {
      try {
        // console.warn(PRINT_URL)
        return await Axios.get(
          `${PRINT_URL}${get(payload, "id", 1)}&${
            payload.type
          }&${DOWNLOAD_MODE}&${payload.organisation}&${get(
            payload,
            "id_demande",
            0
          )}&${get(payload, "exercice", 1)}`,
          { responseType: "blob" }
        ).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "document.pdf");
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) {
        throw e;
      }
    },
  },
};
